








































































































































































































































































































































































































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import InnerImageZoom from "vue-inner-image-zoom";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";
@Component({
  components: {
    PageTitle,
    Loader,
    Empty,
    BarSearch,
    "inner-image-zoom": InnerImageZoom,
  },
})
export default class Payments extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  paymentPreview = null;
  dialogPaymentDetail = false;

  private openPaymentDetail(payment: any) {
    this.paymentPreview = payment;
    this.dialogPaymentDetail = true;
  }

  private closePaymentDetail() {
    this.paymentPreview = null;
    this.dialogPaymentDetail = false;
  }

  private checkPreviewType(previewType: string): boolean {
    let type = previewType.toLowerCase();

    if (
      type == ".jpeg" ||
      type == ".png" ||
      type == ".jpg" ||
      type == ".svg" ||
      type == ".webm"
    ) {
      return true;
    } else {
      return false;
    }
  }

  //INICIO PAGOS TRADICIONALES
  searchTP = "";
  loaderTP = false;
  loadingDTTP = false;
  optionsTP = {};

  paginationTP = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("optionsTP", { deep: true })
  private async setItemsTP(pagination) {
    this.loadingDTTP = true;
    this.paginationTP.itemsPerPage = pagination.itemsPerPage;

    await this.getTraditionalPayment(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.paginationTP.totalItems
        : this.paginationTP.itemsPerPage
    );
    this.loadingDTTP = false;
  }

  private async getTraditionalPayment(
    page: number,
    size: number,
    payment_id = ""
  ): Promise<void> {
    if (!(isNaN(page) || isNaN(size))) {
      await this.$store
        .dispatch("payment/getTraditionalPayments", { page, size, payment_id })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.p-18"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        });
    }
  }

  private get headersTP() {
    return [
      {
        text: this.$tc("Views.p-21"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h2"),
        value: "amount",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h3"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h5"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get traditionalPayments(): any[] {
    let response = this.$store.getters["payment/getTraditionalPayments"];
    this.paginationTP.totalItems = response.totalItems;
    this.paginationTP.page = parseInt(response.currentPage);

    if (response?.payments) {
      return response?.payments;
    } else {
      return [];
    }
  }

  //Search by ID
  private async searchTraditionalPaymentByID() {
    this.loadingDTTP = true;

    await this.getTraditionalPayment(
      this.paginationTP.page,
      this.paginationTP.itemsPerPage == -1
        ? this.paginationTP.totalItems
        : this.paginationTP.itemsPerPage,
      this.searchTP
    );
    this.loadingDTTP = false;
  }
  //FIN PAGOS TRADICIONALES

  //INICIO PAGOS CRYPTO
  searchCP = "";
  loaderCP = false;
  loadingDTCP = false;
  optionsCP = {};

  paginationCP = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("optionsCP", { deep: true })
  private async setItemsCP(pagination) {
    this.loadingDTCP = true;
    this.paginationCP.itemsPerPage = pagination.itemsPerPage;

    await this.getCryptoPayments(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.paginationCP.totalItems
        : this.paginationCP.itemsPerPage
    );
    this.loadingDTCP = false;
  }

  private async getCryptoPayments(
    page: number,
    size: number,
    payment_id = ""
  ): Promise<void> {
    if (!(isNaN(page) || isNaN(size))) {
      await this.$store
        .dispatch("payment/getCryptoPayments", { page, size, payment_id })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.p-19"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        });
    }
  }

  private get headersCP() {
    return [
      {
        text: this.$tc("Views.p-21"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h2"),
        value: "amount",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h3"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h5"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get cryptoPayments(): any[] {
    let response = this.$store.getters["payment/getCryptoPayments"];
    this.paginationCP.totalItems = response.totalItems;
    this.paginationCP.page = parseInt(response.currentPage);

    if (response?.payments) {
      return response?.payments;
    } else {
      return [];
    }
  }

  //Search by ID
  private async searchCryptoPaymentByID() {
    this.loadingDTCP = true;

    await this.getCryptoPayments(
      this.paginationCP.page,
      this.paginationCP.itemsPerPage == -1
        ? this.paginationCP.totalItems
        : this.paginationCP.itemsPerPage,
      this.searchCP
    );
    this.loadingDTCP = false;
  }
  //FIN PAGOS CRYPTO

  //INICIO PAGOS BALANCE
  searchBP = "";
  loaderBP = false;
  loadingDTBP = false;
  optionsBP = {};

  paginationBP = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("optionsBP", { deep: true })
  private async setItemsBP(pagination) {
    this.loadingDTBP = true;
    this.paginationBP.itemsPerPage = pagination.itemsPerPage;

    await this.getBalancePayments(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.paginationBP.totalItems
        : this.paginationBP.itemsPerPage
    );
    this.loadingDTBP = false;
  }

  private async getBalancePayments(
    page: number,
    size: number,
    payment_id = ""
  ): Promise<void> {
    if (!(isNaN(page) || isNaN(size))) {
      await this.$store
        .dispatch("payment/getBalancePayments", { page, size, payment_id })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.p-20"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        });
    }
  }

  private get headersBP() {
    return [
      {
        text: this.$tc("Views.p-21"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h2"),
        value: "amount",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h3"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get balancePayments(): any[] {
    let response = this.$store.getters["payment/getBalancePayments"];
    this.paginationBP.totalItems = response.totalItems;
    this.paginationBP.page = parseInt(response.currentPage);

    if (response?.payments) {
      return response?.payments;
    } else {
      return [];
    }
  }

  //Search by ID
  private async searchBalancePaymentByID() {
    this.loadingDTBP = true;

    await this.getBalancePayments(
      this.paginationBP.page,
      this.paginationBP.itemsPerPage == -1
        ? this.paginationBP.totalItems
        : this.paginationBP.itemsPerPage,
      this.searchBP
    );
    this.loadingDTBP = false;
  }
  //FIN PAGOS BALANCE

  //GENERAL
  private async created() {
    this.loaderTP = true;
    this.loaderCP = true;
    this.loaderBP = true;
    await this.getTraditionalPayment(
      this.paginationTP.page,
      this.paginationTP.itemsPerPage
    );
    await this.getCryptoPayments(
      this.paginationCP.page,
      this.paginationCP.itemsPerPage
    );
    await this.getBalancePayments(
      this.paginationBP.page,
      this.paginationBP.itemsPerPage
    );
    this.loaderTP = false;
    this.loaderCP = false;
    this.loaderBP = false;
  }
}
